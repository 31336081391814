import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
// import MuiBreadcrumbs from "@material-ui/core/Breadcrumbs"
// import EventNoteIcon from "@material-ui/icons/EventNote"

import { graphql, Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// import Seo from "../components/seo"
import Title from "../components/title"
import Hero from "../components/hero"
import Layout from "../components/layout"
import constant from "../helpers/constant"

const useStyles = makeStyles(theme => ({
	grid: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(10),
	},
	item: {},
	link: {
		textDecoration: 'none !important'
	},
	title: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem',
		},
	},
	name: {
		color: theme.palette.text.secondary,
		display: "flex",
		textAlign: "center",
		marginTop: theme.spacing(2.5),
		justifyContent: "center"
	},
	imageContainer: {
		display: "block",
		overflow: "hidden",
	},
	image: {
		backgroundColor: theme.palette.common.white,
	}
}))

const SaddleCategory = ({ data }) => {
	const classes = useStyles()
	const {
		datoCmsHeroImage,
		allDatoCmsPageSaddle
	} = data || {}

	const { title, image } = datoCmsHeroImage || {}
	const saddles = (allDatoCmsPageSaddle && allDatoCmsPageSaddle.nodes) || []

	return (
		<Layout>
			<Hero title={title} image={image} />
			<Container maxWidth="lg">
				{title && (
					<Box mt={10} display="flex" justifyContent="center">
						<Title className={classes.title}>{title}</Title>
					</Box>
				)}
				<Grid container spacing={8} className={classes.grid}>
					{saddles.map(({ id, saddleName, saddleType, saddleImage, slug }) => (
						<Grid item md={4} sm={6} xs={12} key={id} className={classes.item}>
							<Link to={`/siodla/${saddleType}/${slug}`} className={classes.link}>
								{saddleImage && (
									<div className={classes.imageContainer}>
										<GatsbyImage image={getImage(saddleImage)} alt={saddleName} className={classes.image} />
									</div>
								)}
								<Typography variant="h5" className={classes.name}>{saddleName}</Typography>
							</Link>
						</Grid>
					))}
				</Grid>
			</Container>
		</Layout>
	)
}

export const query = graphql`
  query querySaddleType($saddleType: String!) {
    allDatoCmsPageSaddle(
      filter: {saddleType: {eq: $saddleType}}
      sort: {fields: position, order: ASC}
    ) {
      nodes {
        id
        slug
        saddleType
        saddleName
        saddleImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, aspectRatio: 1)
        }
      }
    }
		datoCmsHeroImage(pageType: {eq: $saddleType}) {
			pageType
			title
			image {
				gatsbyImageData
			}
		}
  }
`

export default SaddleCategory
